import { CircularProgress, makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import eventsAPI, {
  EventFullDetails,
  EventId,
  EventResponseForm,
} from '../../api/events';
import venturesAPI from '../../api/ventures';
import { SnackMessage, Text } from '../../components/common';
import WarningBeforeStart from '../../components/common/Confirmation/WarningBeforeStart';
import ReportFounderSessionNotesForm, {
  FormInitialValues,
  FormValues,
} from '../../components/forms/report-founder-session-notes-form';
import { SessionContext } from '../../contexts/session-context';
import { UserContext } from '../../contexts/user-context';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';

enum FounderNotesStatus {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  NO_FOUNDERS = 'NO_FOUNDERS',
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: '560px',
    maxWidth: '100%',
  },
  stateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    width: 300,
    maxWidth: '100%',
    padding: '32px',
    boxSizing: 'border-box',
  },

  loadingBlock: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingContainer: {
    height: '100vh',
    padding: 0,

    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
  errorIcon: {
    fontSize: 30,
    color: COLORS.COLOR_RED_BASE,
    marginBottom: 15,
  },
  errorText: {
    textAlign: 'center',
  },
}));

export const formatValuesRequest = (
  values: FormValues,
  fullDetails: EventFullDetails,
): EventResponseForm => {
  return {
    tenantId: fullDetails.event.tenantId,
    eventId: fullDetails.event.id,
    ventureId: fullDetails.event.ventureId,
    sessionRating: +(values.sessionRating || 0),
    ventureProgressRating: 0,
    attendingMentors: [],
    attendingFounders: [],
    mostImpactfulMentor: values?.mostImpactfulMentor || '',
    submitterEmail: values.submitterEmail,
    mentorReport: '',
    mentorReportAttachments: '',
    mentorReportNotes: '',
    founderNotes: values.beforeNextSession || '',
    founderOtherNotes: values.other || '',
    founderNotesAttachments: values.attachments || '',
    agendaQuestion1: '',
    agendaQuestion2: '',
    agendaQuestion3: '',
    agendaQuestion4: '',
    agendaAttachments: '',
    isLeadMentorReport: false,
    nextEventStart: '',
    nextEventEnd: '',
    isMentorReport: false,
    isFounderReport: true,
    founderReport: false,
    agendaPresented: false,
    ventureReadinessRating: +(values.ventureReadinessRating || 0),
    mentorTeamImpactRating: +(values.mentorTeamImpactRating || 0),
    eventEnjoymentRating: +(values.eventEnjoymentRating || 0),
  };
};

const getInitialValues = (
  fullDetails: EventFullDetails,
  defaultEmail?: string,
): FormInitialValues => {
  return {
    submitterEmail: defaultEmail || '',
    summary: fullDetails?.event.summary || '',
    start: fullDetails?.event.start || '',
    end: fullDetails?.event.end || '',
    sessionRating: 0,
    beforeNextSession: '',
    other: '',
    attachments: [],
    mostImpactfulMentor: '',
    ventureReadinessRating: 0,
    mentorTeamImpactRating: 0,
    eventEnjoymentRating: 0,
  };
};

const errorMessage =
  'Unfortunately an error occurred while loading the report. Please try again in a couple of minutes. If the problem persists please share the URL with the support team at support@tractionfive.com';

function SessionFounderNotesPage() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { sessionId } = useParams<{ sessionId: EventId }>();
  const { user } = useContext(UserContext);
  const { sessionKey } = useContext(SessionContext);

  const [fullDetails, setFullDetails] = useState<EventFullDetails>();
  const [statusForm, setStatusForm] = useState<FounderNotesStatus>(
    FounderNotesStatus.LOADING,
  );
  const [isLoadingSaveForm, setIsLoadingSaveForm] = useState(false);
  const [errorVaildDate, setErrorVaildDate] = useState(false);

  const formSettings = useMemo(
    () => ({
      mostImpactfulMentorEnabled: !!user?.mostImpactfulMentorEnabled,
      founderNotesSessionRatingQuestionEnabled:
        !!user?.founderNotesSessionRatingQuestionEnabled,
      founderNotesVentureReadinessQuestionEnabled:
        !!user?.founderNotesVentureReadinessQuestionEnabled,
      founderNotesMentorTeamImpactQuestionEnabled:
        !!user?.founderNotesMentorTeamImpactQuestionEnabled,
      founderNotesSessionEnjoymentQuestionEnabled:
        !!user?.founderNotesSessionEnjoymentQuestionEnabled,
    }),
    [user],
  );

  const initialValues = useMemo(() => {
    if (fullDetails) {
      return getInitialValues(fullDetails);
    }
    return undefined;
  }, [fullDetails]);

  const optionsMentor = useMemo(() => {
    return fullDetails?.mentorList && fullDetails?.mentorList?.length > 0
      ? fullDetails?.mentorList.map((mentor) => {
          return {
            value: mentor.id,
            label: `${mentor.firstName} ${mentor.lastName}`,
          };
        })
      : [];
  }, [fullDetails?.mentorList]);

  const handleSubmit = async (values: FormValues) => {
    const valuesRequest = formatValuesRequest(
      values,
      fullDetails as EventFullDetails,
    );

    try {
      await eventsAPI.eventsResponseForm(valuesRequest);
      setErrorVaildDate(false);
      enqueueSnackbar('Founder Notes submitted successfully', {
        variant: 'success',
      });
      history.push(
        getRoutePath(Pages.SESSIONS_DETAILS, {
          sessionId,
        }),
      );
      setIsLoadingSaveForm(false);
    } catch (e: any) {
      setIsLoadingSaveForm(false);
      const messageError = e.response?.data?.message || 'Internal server error';
      enqueueSnackbar('An error occurred while saving your report', {
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  const handleUploadFile = useCallback(
    async (file: File) => {
      try {
        if (fullDetails?.event.ventureId) {
          const loadedFileURL = await venturesAPI.attachFileToReport(
            fullDetails.event.ventureId,
            fullDetails.event.tenantId,
            file,
          );
          return loadedFileURL;
        }
      } catch (e: any) {
        return '';
      }
    },
    [fullDetails],
  );

  useEffect(() => {
    const loadDetails = async (key: string) => {
      try {
        setStatusForm(FounderNotesStatus.LOADING);
        const responseDetails = await eventsAPI.getEventDetails(key);
        setFullDetails(responseDetails);

        if (!responseDetails.founderList?.length) {
          setStatusForm(FounderNotesStatus.NO_FOUNDERS);
        } else {
          setStatusForm(FounderNotesStatus.LOADED);
        }
      } catch (e: any) {
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage:
                e.response?.data?.message || 'Internal server error',
            }),
          style: { whiteSpace: 'pre-line' },
        });
      }
    };

    loadDetails(sessionKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionKey]);

  useEffect(() => {
    document.body.style.minWidth = 'auto';
  }, []);

  if (statusForm === FounderNotesStatus.LOADING || !fullDetails) {
    return (
      <div className={classes.stateContainer}>
        <CircularProgress size={54} color='primary' />
      </div>
    );
  }

  if (statusForm === FounderNotesStatus.NO_FOUNDERS) {
    return (
      <div
        data-testid={`report-page-${statusForm}`}
        className={classes.stateContainer}>
        <div className={classes.block}>
          <CancelIcon className={classes.errorIcon} />
          <div className={classes.errorText}>
            <Text variant='normal'>
              You cannot submit Founder Notes for this session since no Founders
              are assigned.
            </Text>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <WarningBeforeStart date={fullDetails.event.start} />
      <div className={classes.formContainer}>
        <ReportFounderSessionNotesForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          loading={isLoadingSaveForm}
          errorVaildDate={errorVaildDate}
          onUploadFile={handleUploadFile}
          optionsMentor={optionsMentor}
          formSettings={formSettings}
          isStickySubmit
        />
      </div>
    </>
  );
}

export default SessionFounderNotesPage;
