import { CircularProgress, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { EventFullDetails } from '../../api/events';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { useScrollOnValidation } from '../../hooks/useScrollOnValidation';
import { formatDateToRFC } from '../../utils/date';
import { lengthField } from '../../utils/form';
import { validateEmail } from '../../utils/functions';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import {
  AttachFilesButton,
  Button,
  FormButtons,
  FormGroup,
  StickyContent,
} from '../common';
import AttachmentCard, {
  Attachment,
  getAttachmentFromFile,
} from '../common/attachment-card';
import {
  FormDateTimeInput,
  FormRating,
  FormSelect,
  TextFieldSummary,
  TextFieldWrapper,
  TextFieldWysiwyg,
} from '../forms/wrappers';

const useStyles = makeStyles((theme) => ({
  formBlocks: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  formContainer: {
    width: '100%',
  },

  ventureUpdate: {
    '& .MuiInputBase-inputMultiline': {
      minHeight: 64,
    },
  },
  attachBtn: {
    marginTop: 24,
  },
  attachmentsList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0 -16px -16px',
  },
  attachment: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 0 16px 16px',

    [theme.breakpoints.up('xs')]: {
      width: '50%',
    },
  },
  progressVenture: {
    marginBottom: '10px !important',
  },
  formButtons: {
    justifyContent: 'flex-start',
  },
  fileName: {
    maxWidth: '200px !important',
  },
  actionsBlock: {
    marginTop: 56,
  },
}));

export interface Params {
  label: string;
  value: string;
}

export interface FormValues {
  submitterEmail: string;
  summary: string;
  start: string;
  end: string;
  sessionRating: number;
  ventureProgressRating: number;
  notes: string;
  rateSession: number;
  rateVenture: number;
  attachments: string;
  mostImpactfulMentor: string;
}

export interface FormInitialValues {
  submitterEmail: string;
  summary: string;
  start: string;
  end: string;
  sessionRating: number;
  ventureProgressRating: number;
  notes: string;
  attachments: Attachment[];
  mostImpactfulMentor: string;
}

interface Props {
  fullDetails: EventFullDetails;
  onSubmit: (values: FormValues) => void;
  onUploadFile: (file: File) => Promise<string | undefined>;
  loading: boolean;
  initialValues?: FormInitialValues;
  errorVaildDate?: boolean;
  optionsMentor: Params[];
  leadingMentor: boolean;
  isStickySubmit?: boolean;
}

type Errors = {
  [K in keyof FormValues]?: string;
};

const validateForm = (
  values: FormValues,
): Partial<Record<keyof FormValues, string>> => {
  const errors: Errors = {};

  if (!values.submitterEmail) {
    errors.submitterEmail = 'Required';
  } else if (!validateEmail(values.submitterEmail)) {
    errors.submitterEmail = 'Invalid';
  }

  if (!values.sessionRating) {
    errors.sessionRating = 'Required';
  }

  if (!values.ventureProgressRating) {
    errors.ventureProgressRating = 'Required';
  }

  return errors;
};

const formatAttachments = (attachments: Attachment[]) => {
  try {
    if (!attachments.length) {
      return '';
    }
    const attachmentRefs = JSON.stringify(attachments);
    return attachmentRefs;
  } catch (e: any) {
    return '';
  }
};

function ReportMentorAssessmentForm({
  fullDetails,
  onSubmit,
  loading,
  initialValues,
  errorVaildDate,
  onUploadFile,
  optionsMentor,
  leadingMentor,
  isStickySubmit,
}: Props) {
  const classes = useStyles();
  const isSubmitDisabled = loading;
  const { rb } = useResourceBundles();
  const setSubmitValidationFailed = useScrollOnValidation();

  const [attachments, setAttachments] = useState<Attachment[]>(
    initialValues?.attachments || [],
  );

  const handleSubmit = (values: FormValues) => {
    const attachmentRefs = formatAttachments(attachments);

    onSubmit({ ...values, attachments: attachmentRefs });
  };

  const handleSelectFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    file: File,
  ) => {
    handleFileUpload(file);
    e.target.value = '';
  };

  const handleFileUpload = async (file: File) => {
    try {
      const fileIndex = attachments.length;
      setAttachments((prevAttachments) => [
        ...prevAttachments,
        getAttachmentFromFile(file),
      ]);
      const fileURL = await onUploadFile(file);
      setAttachments((prevAttachments) => {
        return prevAttachments.map((prevAttach, prevAttachIndex) => {
          if (prevAttachIndex !== fileIndex) {
            return prevAttach;
          }
          return {
            ...prevAttach,
            url: fileURL,
          };
        });
      });
    } catch (e: any) {}
  };

  const handleFileRemove = (index: number) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((_, attachIndex) => attachIndex !== index),
    );
  };

  return (
    <Form
      validate={validateForm}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={(formProps) => {
        setSubmitValidationFailed(
          formProps.submitFailed &&
            !formProps.dirtySinceLastSubmit &&
            !formProps.submitting,
        );
        return (
          <div className={classes.formBlocks}>
            <form noValidate className={classes.formContainer}>
              <FormGroup>
                <Field<string>
                  name='summary'
                  component={TextFieldSummary}
                  label='Summary'
                  disabled
                  testid='report-mentor-assessment-form-summary'
                />
              </FormGroup>
              <FormGroup mobile>
                <Field<string>
                  name='start'
                  component={FormDateTimeInput}
                  label='Start'
                  parse={(value) => formatDateToRFC(value)}
                  disabled
                  testId='report-mentor-assessment-form-end'
                />
                <Field<string>
                  name='end'
                  component={FormDateTimeInput}
                  label='End'
                  parse={(value) => formatDateToRFC(value)}
                  disabled
                  testId='report-mentor-assessment-form-end'
                />
              </FormGroup>
              <FormGroup>
                <Field<string>
                  name='submitterEmail'
                  component={TextFieldWrapper}
                  label='Email*'
                  testid='report-mentor-assessment-form-email'
                  InputProps={{
                    inputProps: {
                      maxLength: lengthField.email,
                    },
                  }}
                  formatOnBlur
                  format={(value: string) => {
                    return value ? value.toLowerCase() : value;
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Field<number>
                  name='sessionRating'
                  component={FormRating}
                  label='How would you rate the session? *'
                  testid='report-mentor-assessment-form-session-rating'
                />
              </FormGroup>
              <FormGroup>
                <Field<number>
                  name='ventureProgressRating'
                  component={FormRating}
                  label='How would you rate the progress of the venture? *'
                  className={classes.progressVenture}
                  testid='report-mentor-assessment-form-venture-rating'
                />
              </FormGroup>
              <FormGroup>
                <Field<string>
                  name='notes'
                  label='Notes'
                  placeholder='Type here...'
                  testid='report-mentor-assessment-form-notes'
                  component={TextFieldWysiwyg}
                  className={classes.ventureUpdate}
                  InputProps={{
                    inputProps: {
                      maxLength: lengthField.ventureUpdate,
                    },
                  }}
                />
              </FormGroup>
              {leadingMentor && (
                <FormGroup>
                  <Field<string>
                    name='mostImpactfulMentor'
                    testid='mentor-assessment-high-value-mentor-id'
                    component={FormSelect}
                    label={`Who was the most impactful ${rb(
                      'mentor',
                    )} during the session?`}
                    options={optionsMentor}
                  />
                </FormGroup>
              )}

              <div className={classes.attachBtn}>
                <AttachFilesButton onChange={handleSelectFile} />
              </div>
              <div className={classes.attachmentsList}>
                {attachments.map((attachment, attachIndex) => (
                  <div key={attachIndex} className={classes.attachment}>
                    <AttachmentCard
                      attachment={attachment}
                      loading={!attachment.url}
                      onRemove={() => handleFileRemove(attachIndex)}
                      classesFileName={classes.fileName}
                    />
                  </div>
                ))}
              </div>
              {isStickySubmit ? (
                <div className={classes.actionsBlock}>
                  <StickyContent>
                    <Button
                      className={CLASS_TRACKING.INTERNAL_ACTION}
                      onClick={formProps.handleSubmit}
                      disabled={isSubmitDisabled}
                      data-testid='button-submit-form'
                      startIcon={<CheckIcon />}>
                      {loading ? (
                        <CircularProgress size={24} color='inherit' />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </StickyContent>
                </div>
              ) : (
                <FormButtons className={classes.formButtons}>
                  <Button
                    data-testid='button-submit-form'
                    onClick={formProps.handleSubmit}
                    disabled={isSubmitDisabled}>
                    {loading ? (
                      <CircularProgress size={24} color='inherit' />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </FormButtons>
              )}
            </form>
          </div>
        );
      }}
    />
  );
}

export default ReportMentorAssessmentForm;
