import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import cn from 'classnames';
import { parse as parseQuery } from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import _pick from 'lodash/pick';
import eventsAPI, { EventFullDetails } from '../../api/events';
import { getSettings } from '../../api/tenants';
import { Settings, TenantTimeZone } from '../../api/tenants/types/Settings';
import { Text } from '../../components/common';
import LeadMentorReport from '../../components/sessions/reports/lead-mentor-report';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { COLORS } from '../../theme/variables';
import { getTenantId } from './utils';

enum LeadMentorReportStatus {
  LOADED = 'LOADED',
  SUCCESS = 'SUCCESS',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUBMITTED = 'SUBMITTED',
  NO_MENTORS = 'NO_MENTORS',
  NO_FOUNDERS = 'NO_FOUNDERS',
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 0,

    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 60px)',
      padding: '50px 30px',
    },

    [theme.breakpoints.up('md')]: {
      width: '100%',
      padding: '50px 0',
    },
  },
  block: {
    width: '700px',
    padding: '32px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    borderRadius: 0,
    boxSizing: 'border-box',

    [theme.breakpoints.up('xs')]: {
      borderRadius: '16px',
    },
  },
  loadingBlock: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingContainer: {
    height: '100vh',
    padding: 0,

    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
  successBlock: {
    width: 300,
    minHeight: 180,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    borderRadius: '16px',
  },
  successIcon: {
    fontSize: 30,
    color: COLORS.COLOR_GREEN_BASE,
    marginBottom: 15,
  },
  errorIcon: {
    fontSize: 30,
    color: COLORS.COLOR_RED_BASE,
    marginBottom: 15,
  },
  successText: {
    textAlign: 'center',
  },
  title: {
    marginBottom: 15,
  },
}));

function parseKey(search: string): string | undefined {
  const { key } = _pick(parseQuery(search), ['key']);

  return typeof key === 'string' ? key : undefined;
}

interface EventAdditionalDetails {
  defaultLocation: string | undefined;
  tenantTimezone: TenantTimeZone;
  reportBasedSchedulingEnabled: boolean;
  formSettings: Partial<Settings>;
}

function ReportPage() {
  const classes = useStyles();
  const location = useLocation();
  const { rb } = useResourceBundles();

  const [fullDetails, setFullDetails] = useState<EventFullDetails>();
  const [additionalDetails, setAdditionalDetails] =
    useState<EventAdditionalDetails>();
  const [statusForm, setStatusForm] = useState<LeadMentorReportStatus>(
    LeadMentorReportStatus.LOADING,
  );
  const [error, setError] = useState('');

  const key = useMemo(() => parseKey(location.search), [location.search]);

  const loadDetails = async (key: string) => {
    const tenantId = getTenantId(key);

    if (!tenantId) {
      setError('Invalid key value');
      setStatusForm(LeadMentorReportStatus.ERROR);
      return;
    }

    try {
      setStatusForm(LeadMentorReportStatus.LOADING);
      const isSubmitted = await eventsAPI.getLeadMentorReportSubmission(key);

      if (isSubmitted) {
        setStatusForm(LeadMentorReportStatus.SUBMITTED);
        return;
      }

      const responseDetails = await eventsAPI.getEventDetails(key);
      const noMentors = !responseDetails.mentorList?.length;
      const noFounders = !responseDetails.founderList?.length;

      if (noMentors) {
        setStatusForm(LeadMentorReportStatus.NO_MENTORS);
        return;
      } else if (noFounders) {
        setStatusForm(LeadMentorReportStatus.NO_FOUNDERS);
        return;
      }

      const {
        mostImpactfulMentorEnabled,
        defaultPhysicalLocation,
        timeZone,
        reportBasedSchedulingEnabled,
        mentorReportSessionRatingQuestionEnabled,
        mentorReportVentureProgressQuestionEnabled,
        mentorReportVentureReadinessQuestionEnabled,
        mentorReportMentorTeamImpactQuestionEnabled,
        mentorReportSessionEnjoymentQuestionEnabled,
      } = await getSettings(tenantId);
      setFullDetails(responseDetails);
      setAdditionalDetails({
        defaultLocation: defaultPhysicalLocation ?? undefined,
        tenantTimezone: timeZone,
        reportBasedSchedulingEnabled,
        formSettings: {
          mentorReportSessionRatingQuestionEnabled,
          mentorReportVentureProgressQuestionEnabled,
          mentorReportVentureReadinessQuestionEnabled,
          mentorReportMentorTeamImpactQuestionEnabled,
          mentorReportSessionEnjoymentQuestionEnabled,
          mostImpactfulMentorEnabled,
        },
      });
      setStatusForm(LeadMentorReportStatus.LOADED);
    } catch (e: any) {
      setError(e.response?.data?.message || 'Internal server error');
      setStatusForm(LeadMentorReportStatus.ERROR);
    }
  };

  useEffect(() => {
    if (key) {
      loadDetails(key);
    } else {
      setError('Invalid key value');
      setStatusForm(LeadMentorReportStatus.ERROR);
    }
  }, [key]);

  useEffect(() => {
    document.body.style.minWidth = 'auto';
  }, []);

  if (statusForm === LeadMentorReportStatus.LOADING) {
    return (
      <div
        data-testid='report-page'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.loadingBlock)}>
          <CircularProgress size={54} color='primary' />
        </div>
      </div>
    );
  }

  if (statusForm === LeadMentorReportStatus.SUCCESS) {
    return (
      <div
        data-testid='report-page-success'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.successBlock)}>
          <CheckCircleIcon className={classes.successIcon} />
          <div className={classes.successText}>
            <Text variant='normal'>Success!</Text>
          </div>
        </div>
      </div>
    );
  }

  if (
    [
      LeadMentorReportStatus.SUBMITTED,
      LeadMentorReportStatus.NO_MENTORS,
      LeadMentorReportStatus.NO_FOUNDERS,
    ].includes(statusForm)
  ) {
    return (
      <div
        data-testid={`report-page-${statusForm}`}
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.successBlock)}>
          {statusForm === LeadMentorReportStatus.SUBMITTED ? (
            <CheckCircleIcon className={classes.successIcon} />
          ) : (
            <CancelIcon className={classes.errorIcon} />
          )}
          <div className={classes.successText}>
            <Text variant='normal'>
              {(() => {
                switch (statusForm) {
                  case LeadMentorReportStatus.SUBMITTED:
                    return `A lead ${rb(
                      'mentor',
                    )} report for this session was already submitted. You cannot re-submit the report again. If you believe this is an error, please consult with the program admin.`;
                  case LeadMentorReportStatus.NO_MENTORS:
                    return `You cannot submit a Lead ${rb(
                      'mentor-u',
                    )} Report for this session since no ${rb(
                      'mentors-u',
                    )} are assigned.`;
                  case LeadMentorReportStatus.NO_FOUNDERS:
                    return `You cannot submit a Lead ${rb(
                      'mentor-u',
                    )} Report for this session since no Founders are assigned.`;
                }
              })()}
            </Text>
          </div>
        </div>
      </div>
    );
  }

  if (
    statusForm === LeadMentorReportStatus.ERROR ||
    !fullDetails ||
    !additionalDetails
  ) {
    return (
      <div
        data-testid='report-page-error'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.successBlock)}>
          <CancelIcon className={classes.errorIcon} />
          <div className={classes.successText}>
            <Text variant='normal'>
              Unfortunately an error occurred while loading the report. Please
              try again in a couple of minutes. If the problem persists please
              share the URL with the support team at support@tractionfive.com
              <br />
              Details: {error}
            </Text>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div data-testid='report-page' className={classes.container}>
        <div className={classes.block}>
          <Typography variant='h2' className={classes.title}>
            Lead {rb('mentor-u')} Report
          </Typography>
          <LeadMentorReport
            payload={fullDetails}
            formSettings={additionalDetails.formSettings}
            timeZone={additionalDetails.tenantTimezone}
            reportBasedSchedulingEnabled={
              additionalDetails.reportBasedSchedulingEnabled
            }
            defaultLocation={additionalDetails.defaultLocation}
            onSuccess={() => setStatusForm(LeadMentorReportStatus.SUCCESS)}
          />
        </div>
      </div>
    </>
  );
}

export default ReportPage;
